import React from 'react'
import styled from 'styled-components'

import PrimaryButton from '../components/Buttons/PrimaryButton.js'

import ExpandRateResult from '../components/Misc/ExpandRateResult.js'

const AccordionComponentSelectCarrier = styled.div`
.es-c-accordion--carrier {
  // 🎛
  // 🎨
  border-bottom: 1px solid #bbbbbb;
  border-top: 1px solid #bbbbbb;
  transition: 0.6s cubic-bezier(0.2, 0.8, 0.2, 1);
  &:hover {
    background-color: #f7f7f7;
  }
  // 🖋
  font-family: 'Open Sans';
  color: #0033a1;
  // 📰
  display: grid;
  grid-template-rows: 20px 60px 0.2fr;
  grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
  grid-template-areas:
    "t_title t_title t_title t_title c_carrier_price"
    "c_img t_transit_time t_est_delivery t_cargo_liability c_carrier_price"
    "c_more_accordion c_more_accordion c_more_accordion c_more_accordion c_more_accordion";

  
  .es-c-carrier--img {
    grid-area: c_img; // 🗺
    vertical-align: middle;
    .carrier-thumbnail {
      width: 60px !important;
    }

  }
  .es-t-carrier--transit-time {
    grid-area: t_transit_time; // 🗺
    justify-self: center;
    .es-t-days {
      font-weight: 400;
      color: #141b1f;
      margin-bottom: 0;
      font-size: 18px;
      margin-top: 4px;
    }
    .es-t-direct {
      color: #777;
      font-size: 12px;
      white-space: nowrap;
      font-style: italic;
    }
  }
  .es-t-carrier--est-delivery {
    grid-area: t_est_delivery; // 🗺
    justify-self: center;
    .es-t-date {
      font-weight: 400;
      color: #141b1f;
      margin-bottom: 0;
      font-size: 14px;
      margin-top: 4px;
    }
  }
  .es-t-carrier--cargo-liability {
    grid-area: t_cargo_liability; // 🗺
    justify-self: center;
    p {
      color: #666;

      margin: 0px;
    span {
        color: #666;
        font-weight: 400;
        color: #141b1f;
        margin-bottom: 0;
      }
    }
  }
  .es-c-more-accordion {
    grid-area: c_more_accordion; // 🗺
    color: #0033a1;
    cursor: pointer;
    font-size: 12px;
  }
  .es-c-carrier--price {
    padding-top: 15px;
    grid-area: c_carrier_price; // 🗺
    justify-self: center;
    p {
      font-size: 20px;
      font-weight: 400;
      text-align: center;
      color: #2b363b;
      margin: 0px;
    }
  }


 .es-t-label {
  font-size: 12px;
  letter-spacing: .3px;
  color: #666;
  font-weight: 600;
  margin: 0px;
 }
}
`;

const AccordionTitle = styled.div `
    grid-area: t_title; // 🗺
    font-size: 12px;
    text-transform: uppercase;
    color: #2b363b;
    padding-bottom: 6px;

`


const AccordionComponent = (props) => {
  return (
    <AccordionComponentSelectCarrier>
      <div className="es-c-accordion--carrier">

        <AccordionTitle>{props.title}</AccordionTitle>
       
        <div className="es-c-carrier--image">
          <img
            className="carrier-thumbnail"
            src={props.image} 
          />
        </div>
        <div className="es-t-carrier--transit-time">
          <p className="es-t-label">Transit Time</p>
          <p className="es-t-days">2 Days</p>
          <p className="es-t-direct">Direct</p>
        </div>
        <div className="es-t-carrier--est-delivery">
          <p className="es-t-label">Estimated Delivery</p>
          <p className="es-t-date">Thu, Feb 21</p>
        </div>
        <div className="es-t-carrier--cargo-liability">
          <p className="es-t-label">Cargo Liability</p>
          <p>
            <span>$68.00</span> New
          </p>
          <p>
            <span>$3.40</span> Used
          </p>
        </div>
        <div className="es-c-carrier--price">
          <p>$126.42</p>
        <PrimaryButton orange>Select</PrimaryButton>
        </div>
        <div className="es-c-more-accordion">
          <div className="accordion">
            <input
              type="checkbox"
              id={props.htmlFor}
              name="accordion-checkbox"
              hidden
            />
            <label className="accordion-header" htmlFor={props.htmlFor} >
              <i className="icon icon-arrow-right mr-1" />
              More Info
            </label>
            <div className="accordion-body">
              <ExpandRateResult></ExpandRateResult>
            </div>
          </div>
        </div>
      </div>
    </AccordionComponentSelectCarrier>
  )
}

export default AccordionComponent;

