import React from 'react'
import styled from 'styled-components'
import { Link } from 'gatsby'

// Import Page Level Components
import FilterGroupComponent from '../../../Organisms/FilterGroupComponent.js'
import AccordionComponentSelectCarrier from '../../../AccordionComponents/SelectCarrierAccordion.js'
import QuoteSummaryGroupComponent from '../../../PageComponents/Summary/QuoteSummaryGroupComponent.js'


const GContentSelectCarrier = styled.div`

  margin: 0 auto;
  max-width: 1360px;
  background-color: #fff;
  // 🖋
  font-family: 'Open Sans';
  // 📰
  display: grid;
  grid-template-rows: minmax(1fr, 1fr) 1fr;
  grid-template-columns: 0.6fr 2fr 0.6fr;
  grid-template-areas:
    "sg_filter sg_carrier sg_quote_summary";

  .SGFilter {
    grid-area: sg_filter; // 🗺
    padding-right: 20px;
  }
  .SGCarrier {
    grid-area: sg_carrier; // 🗺
    overflow: auto; 
    .AccordionComponentSelectCarrier {
      margin-right: 20px;
    }
  }
  .SGQuoteSummary {
    grid-area: sg_quote_summary; // 🗺
    position: sticky;
    top: 0;
    padding-left: 10px;
  }
`;

const SGFilter = styled.div`
    padding-right: 8px;
`;
const SGCarrier = styled.div`
    border-right: 2px solid #f8f8f8;
    padding-right: 8px;
    padding-left: 8px;
`;
const SGQuoteSummary = styled.div`
    padding-left: 8px;
`;
const SGCarrierTitle = styled.p`
`



const GridComponentSelectCarrier = () => {
  return (

    <GContentSelectCarrier>


        <SGFilter>
          <FilterGroupComponent></FilterGroupComponent>
        </SGFilter>
        <SGCarrier>
          <SGCarrierTitle>31 Carriers</SGCarrierTitle>

          <Link to="/quote/ReviewAndDispatch/">
            <AccordionComponentSelectCarrier 
              title="FEDEX ECONOMY"
              htmlFor="accordion-1"
              image={('https://res.cloudinary.com/dj1xukpnf/image/upload/v1552681937/fedex-economy.jpg')} />
          </Link>

          <AccordionComponentSelectCarrier 
            title="FedEx Priority"
            htmlFor="accordion-2"
            image={('https://res.cloudinary.com/dj1xukpnf/image/upload/v1552681937/fedex-economy.jpg')} />

          <AccordionComponentSelectCarrier 
            title="SAIA MOTOR FREIGHT LINE"
            htmlFor="accordion-3"
            image={('https://res.cloudinary.com/dj1xukpnf/image/upload/v1552681943/sala.jpg')} />

          <AccordionComponentSelectCarrier 
            title="Central Transport International"
            htmlFor="accordion-4"
            image={('https://res.cloudinary.com/dj1xukpnf/image/upload/v1550594357/accordion-thumbnail-1.png')} />

          <AccordionComponentSelectCarrier 
            title="RoadRunner Dawes Freight Systems Inc."
            htmlFor="accordion-5"
            image={('https://res.cloudinary.com/dj1xukpnf/image/upload/v1552681941/road-runner.jpg')} />

          <AccordionComponentSelectCarrier 
            title="XPO logistics freight inc"
            htmlFor="accordion-6"
            image={('https://res.cloudinary.com/dj1xukpnf/image/upload/v1552681945/xpo-logistics.png')} />
        </SGCarrier>
        <SGQuoteSummary>
            <QuoteSummaryGroupComponent></QuoteSummaryGroupComponent>
        </SGQuoteSummary>


    </GContentSelectCarrier>

  )
}

export default GridComponentSelectCarrier;