import React from 'react'
import styled from 'styled-components'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { library } from '@fortawesome/fontawesome-svg-core'
import { faLongArrowAltRight } from '@fortawesome/free-solid-svg-icons'

library.add(faLongArrowAltRight)


const QuoteSummaryGroupComponent = styled.div`

  font-family: 'Open Sans';
  color: #0033a1;
  // 📰
  display: grid;
  grid-template-rows: 70px 30px 60px 60px 40px 60px 130px;
  grid-template-columns: 1fr;
  grid-template-areas:
    "sg_title"
    "sg_route"
    "sg_route_info"
    "sg_item"
    "sg_lb_freight_class"
    "sg_underline_seperator"
    "sg_fine_print";
`;

const SgTitle = styled.p`
    grid-area: sg_title; // 🗺
    font-family: Teko,'Helvetica Neue',Helvetica,Arial,sans-serif;
    text-transform: uppercase;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    font-weight: 400;
    line-height: 1.5;
    font-size: 30px;
    color: #141b1f;
`;

const SgRoute = styled.p`
    grid-area: sg_route; // 🗺
    font-family: "Open Sans";
    color: #2b363b;
    font-size: 14px;
    font-weight: 700;
`;

const SgRouteInfo = styled.p`
    grid-area: sg_route_info; // 🗺
    font-family: 'Open Sans';
    color: #2b363b;
    font-size: 18px;
    font-weight: 700;
    margin-bottom: 20px;
`;

const SgItem = styled.p`
    grid-area: sg_item; // 🗺
    font-family: 'Open Sans';
    color: #2b363b;
    font-size: 14px;
    font-weight: 700;
    margin-bottom: 10px;
`;

const SgLbFreightClass = styled.p`
    grid-area: sg_lb_freight_class; // 🗺
    font-family: 'Open Sans';
    font-size: 15px;
    font-weight: 400;
    color: #141b1f;
    margin-bottom: 0;
`;

const SgUnderlineSeperator = styled.div`
    grid-area: sg_underline_seperator; // 🗺
    margin-top: 30px;
    margin-bottom: 20px;
    border: 0;
    border-top: 1px solid #e7edf0;
`;

const SgFinePrint = styled.p`
    grid-area: sg_fine_print; // 🗺
    font-family: 'Open Sans';
    font-size: 12px;
    color: #a9adaf;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
`;

const EchoShipGroupComponent = () => {
  return (

    <QuoteSummaryGroupComponent>

      <SgTitle>Quote Summary</SgTitle>
      <SgRoute>ROUTE</SgRoute>
      <SgRouteInfo>Chicago, IL 60618  <FontAwesomeIcon icon="long-arrow-alt-right" /> Beverly Hills, CA 90210</SgRouteInfo>
      <SgItem>1 Item</SgItem>
      <SgLbFreightClass>23 LBs <br /> 65 Freight Class </SgLbFreightClass>
      <SgUnderlineSeperator></SgUnderlineSeperator>
      <SgFinePrint>These are estimated transit times and rates based on business days for Standard LTL shipments and are not guaranteed. Inside delivery, liftgate, holiday, appointment scheduling, indirect shipments and other factors may add additional time for transit. Please contact an Echo Sales Representative for all of your specific pricing and scheduling needs, including guaranteed and expedited shipments.</SgFinePrint>

    </QuoteSummaryGroupComponent>

  )
}

export default EchoShipGroupComponent;